/*
 * @Author: liujian
 * @Description: banner轮播图
 * @Date: 2021-09-08 10:55:40
 * @LastEditTime: 2022-10-20 17:00:36
 * @FilePath: /three-class-fe-1/src/common/enums/banner-enum.ts
 */
export enum BannerEnum {
    Home = 1,
    Courier,
    FamousTeacher,
    FamousSchool,
    ResourceCenter,
    AirCourse,
    LIVE = 7,
    RECORD,
    OpenLive
}
