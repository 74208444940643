/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-08 10:15:56
 * @LastEditTime: 2023-07-14 13:43:49
 * @FilePath: /three-class-fe-1/src/common/enums/course-type-enum.ts
 */
export enum CourseTypeEnum {
    // 专递课堂 -直播
    CourierCourseLive = 1,
    // 专递课堂 -点播
    CourierCourseVideo,
    // 名师课堂 - 直播教研
    TeacherResearchLive,
    // 名师课堂 - 录播教研
    TeacherResearchVideo,
    // 名师课堂 -直播评课
    TeacherCommentLive,
    // 名师课堂 -点播评课
    TeacherCommentVideo,
    // 名校课堂 -直播
    SchoolLive,
    // 名校课堂 -点播
    SchoolVideo,

    // 专递三方推流直播
    CourierPushLive = 101,
    // 名师教研三方推流直播
    TeacherResearchPushLive = 211,
    // 名师评课三方推流直播
    TeacherCommentPushLive = 221,
    // 名校三方推流直播
    SchoolPushLive = 301,
    //直播频道
    LiveCourse = 601,
    //开放直播
    OpenLive = 701,
    //录播频道
    RecordCourse = 502,
    //融合课堂大班课
    AirBigCourse = 401,
    //融合课堂小班课
    AirSmallCourse = 402
}
