<template>
    <div class="top">
        <div class="header">
            <div class="nav-logo-wrap">
                <img :src="state.systemLogo ? state.systemLogo : defaultSystemLogo" alt="logo" />
            </div>
            <ul class="nav">
                <li
                    :class="(state.currentTab === menu.path ? 'active' : '') + ' tab-item'"
                    v-for="(menu, index) in state.menuList"
                    @click="toMenu(menu)"
                    :key="index"
                >
                    <span class="nav-text">{{ menu.name }}</span>
                </li>
                <el-dropdown popper-class="more-tab-menu" v-show="state.moreTab.length">
                    <div class="trigger" :class="(state.currentTab.includes('moreMenu') ? 'active' : '') + ' tab-item'">
                        更多
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="(menu, index) in state.moreTab"
                                :key="index"
                                @click="toMenu(menu)"
                                :class="(state.currentTab.includes(menu.path) ? 'active' : '') + ' tab-item'"
                            >
                                <span class="nav-text">{{ menu.name }}</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                
            </ul>
            <div class="user-info">
                <div v-if="state.isLogin" class="info">
                    <img
                        class="avatar"
                        :src="
                            userStore.getUserInfo.avatar
                                ? userStore.getUserInfo.avatar
                                : userStore.getUserInfo.isStudent
                                ? avatorStudent
                                : defaultImagePath
                        "
                        alt=""
                    />
                    <el-dropdown popper-class="user-drop-down-menu">
                        <div class="trigger">
                            <span class="name secondary-title" :title="state.realName">{{ state.realName }}</span>
                            <div class="down"><em class="iconfont icon-a-17"></em></div>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item divided>
                                    <em class="iconfont icon-a-85"></em>
                                    <p class="label" @click="routeToOther('personal-center/information')">个人中心</p>
                                </el-dropdown-item>
                                <el-dropdown-item v-if="state.isManage">
                                    <em class="iconfont icon-a-09"></em>
                                    <p class="label" @click="routeToOther('manage/loginLoading')">管理后台</p>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <em class="iconfont icon-a-66"></em>
                                    <p class="label" @click="state.passwordDialogVisible = true">修改密码</p>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <em class="iconfont icon-a-23"></em>
                                    <p class="label" @click="loginOut">退出登录</p>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
                <p v-else @click="login" class="login">登录/注册</p>
            </div>
        </div>
        <el-dialog v-model="state.passwordDialogVisible" title="修改密码" width="420px" destroy-on-close @close="closePasswordDialog">
            <div>
                <el-form ref="passwordForm" :model="state.passWordModel" :rules="rules" label-width="80px" class="demo-dynamic">
                    <el-form-item prop="passwordFirst" label="新密码" required>
                        <el-input
                            v-model="state.passWordModel.passwordFirst"
                            show-password
                            placeholder="6-12位字符, 须包含字母及数字"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="passwordSecond" label="确认密码" required>
                        <el-input
                            v-model="state.passWordModel.passwordSecond"
                            show-password
                            placeholder="6-12位字符, 须包含字母及数字"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="state.passwordDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="confirmUpdatePassword">确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
    import { reactive, onMounted, ref, watch } from 'vue'
    import { useRouter, useRoute, RouteRecordRaw } from 'vue-router'
    import { RouterName, RouterPath } from '@/router/constant'
    import { useLoginPopupStoreWithOut } from '@/store/modules/loginPopup'
    import { UserInfo, useUserStoreWithOut } from '@/store/modules/user'
    import { logout, isLogin } from '@/service/api/login'
    import { getUserInfo, updatePassword } from '@/service/api/user'
    import notification from '@/utils/notification'
    import defaultImagePath from '@/assets/img/user/default-user.png'
    import avatorStudent from '@/assets/img/default/avator_student.jpg'
    import defaultSystemLogo from '@/assets/img/zcw_logo.png'
    import { openNewPage } from '@/common/hooks/blankNewPage'
    import { cloneDeep, debounce } from 'lodash'

    type MenuTab = {
        name: string | unknown
        path: string
        order: number | unknown
    }

    const router = useRouter()
    const route = useRoute()
    const loginPopupStore = useLoginPopupStoreWithOut()
    const userStore = useUserStoreWithOut()

    const state = reactive({
        menuList: [] as MenuTab[],
        currentTab: 'home',
        realName: '',
        isLogin: false,
        isStudent: true,
        isManage: false,
        isTeacher: false,
        identityDes: '',
        passwordDialogVisible: false,
        passWordModel: {
            passwordFirst: '',
            passwordSecond: ''
        },
        userId: '',
        systemLogo: '',
        systemName: '',
        queryZone: '',
        zoneTree: [] as any[],
        zone_name: '',
        zone_id: localStorage.getItem('zone_id'),
        moreTab: [] as MenuTab[]
    })

    const props = {
        value: 'id',
        label: 'name',
        children: 'children',
        class: function (data: any, node: any) {
            if (state.zone_id == node.data.id) {
                node.isCurrent = true
                return ''
            }
        }
    }

    /**
     * 路由变化，变更banner
     */
    watch(
        () => route.fullPath,
        () => {
            initRoutePath()
        }
    )

    watch(
        () => userStore.getUserInfo.userId,
        (newValue) => {
            // 这里荣冗余了，二期接口不一致
            if (!!newValue) {
                initUserInfo()
                state.isLogin = true
            }
        }
    )

    /**
     * 初始化导航列表
     */
    const initTabMenuList = () => {
        state.menuList = getTabMenuList(router.options.routes)
    }

    /**
     * 获取导航菜单
     */
    const getTabMenuList = (routes: RouteRecordRaw[], result: MenuTab[] = []) => {
        for (const element of routes) {
            const item = element
            if (item.meta?.isMenuTab) {
                result.push({
                    name: item.meta.label,
                    path: item.path,
                    order: item.meta.order
                })
            }
            if (item.children) {
                getTabMenuList(item.children, result)
            }
        }
        result = result.sort((a: any, b: any) => a.order - b.order)
        return result
    }

    /**
     * 修改密码form
     */
    const passwordForm = ref<{ validate: Function; clearValidate: Function }>({
        validate: (valid: boolean) => {},
        clearValidate: () => {}
    })

    /**
     * 验证登录密码
     */
    const validatePassword = (rule: { field: string }, value: string, callback: Function) => {
        const passwordReg = /^(?=.*[a-zA-Z])(?=.*[\d])[A-Za-z0-9]{6,12}$/g
        if (!value) {
            return callback(new Error('新密码不能为空'))
        }
        if (!passwordReg.test(value)) {
            return callback(new Error('输入的密码格式有误'))
        } else if (rule.field === 'passwordSecond' && state.passWordModel.passwordFirst !== state.passWordModel.passwordSecond) {
            return callback(new Error('两次输入的密码不一致'))
        } else {
            callback()
        }
    }

    /**
     * 登录校验规则
     */
    const rules = {
        passwordFirst: [{ required: true, trigger: 'blur', validator: validatePassword }],
        passwordSecond: [{ required: true, trigger: 'blur', validator: validatePassword }]
    }

    /**
     * 清除登录弹窗
     */
    router.afterEach(() => {
        loginPopupStore.clearLoginPopupInfo()
    })

    /**
     * 根据tab跳转
     */
    const toMenu = (menu: any = {}) => {
        if (menu.path === RouterPath.CENTER_APPLY) {
            const url = location.origin + '/' + 'personal-center/competitionDetail'
            openNewPage(url)
        }
        else if (menu.path === RouterPath.COMPETITION) {
            const url = location.origin + '/' + 'personal-center/competition'
            openNewPage(url)
        }
        else if (menu.path === RouterPath.PERSONAL_CENTER) {
            const url = location.origin + '/' + 'personal-center/information'
            openNewPage(url)
        }
        else if (menu.path === RouterPath.INFO_DOWNLOAD) {
            const url = location.origin + '/' + 'personal-center/infoSheet'
            openNewPage(url)
        }
        else if(menu.path === RouterPath.GRADES) {
            const url = location.origin + '/' + 'personal-center/grades'
            openNewPage(url)
        }
        else {
            router.push('/' + menu.path)
            state.currentTab = menu.path
        }
    }

    /**
     * 刷新重刷时处理当前选中的路由
     */
    const initRoutePath = () => {
        const routePathArr = route.fullPath.split('/')
        if (routePathArr.length) {
            const currentTab = state.menuList.find((e) => e.path === routePathArr[1])
            if (currentTab) {
                state.currentTab = currentTab.path
            }
            for (let i = 0; i < state.moreTab.length; i++) {
                const pathValue = state.moreTab[i].path;
                if (routePathArr.includes(pathValue)) {
                    state.currentTab = 'moreMenu-' + state.moreTab[i].path
                }
            }
        }
    }

    /**
     * 退出登录
     */
    const loginOut = async () => {
        const res = await logout()
        if (res) {
            userStore.setUserInfo(new UserInfo())
            router.push({
                name: RouterName.LOGIN
            })
        }
    }

    /**
     * 登录
     */
    const login = () => {
        loginPopupStore.setWillPathName(route.name as RouterName)
        loginPopupStore.setWillPathParams(route.params)
        loginPopupStore.setShowLoginPopup(true)
    }

    /**
     * 验证是否登录
     */
    const verifyLogin = async () => {
        const loginRes = await isLogin()
        if (loginRes) {
            initUserInfo()
            return
        }
        userStore.setUserInfo(new UserInfo())
    }

    /**
     * 获取用户信息
     */
    const initUserInfo = async () => {
        const res = await getUserInfo()
        if (res) {
            state.realName = res.name
            state.isStudent = res.is_student
            state.isManage = res.role_id === 1 || res.role_id === 2
            state.isTeacher = res.is_teacher
            state.identityDes = res.role_name
            state.userId = res.user_id

            const info = {
                realName: res.name,
                role: res.role_name,
                userId: res.user_id,
                avatar: res.cover_url,
                isAdmin: res.is_admin,
                isManage: res.is_manage,
                isStudent: res.is_student,
                isTeacher: res.is_teacher
            }
            userStore.setUserInfo(info)
            state.isLogin = true
        }
    }

    /**
     * 确认修改密码
     */
    const confirmUpdatePassword = async () => {
        passwordForm.value.validate(async (valid: boolean) => {
            if (!valid) {
                return
            }
            const res = await updatePassword({ 
                password: state.passWordModel.passwordFirst, 
                confirm_password: state.passWordModel.passwordSecond, 
                id: userStore.getUserInfo.userId })
            if (res) {
                let seconds = 3
                const timeOutId = setInterval(() => {
                    --seconds
                    if (seconds === 0) {
                        router.push({ path: '/login' })
                        clearInterval(timeOutId)
                    }
                }, 1000)
                passwordForm.value.clearValidate()
                state.passwordDialogVisible = false
                notification('success', `修改密码成功, ${seconds}秒后将跳转到登录页面`)
            }
        })
    }

    /**
     * 跳转到后台、个人中心
     */
    const routeToOther = (base: string) => {
        const url = location.origin + '/' + base
        window.open(url)
    }

    /**
     * 关闭修改密码弹框
     */
    const closePasswordDialog = () => {
        state.passwordDialogVisible = false
        state.passWordModel.passwordFirst = ''
        state.passWordModel.passwordSecond = ''
        passwordForm.value.clearValidate()
    }
    onMounted(() => {
        verifyLogin()
        initTabMenuList()
        initRoutePath()
    })
</script>

<style lang="scss">
    .top {
        width: 100%;
        background: #fff;
        .header {
            min-width: 1200px;
            max-width: 1200px;
            height: 72px;
            display: flex;
            margin: 0 auto;
            .nav-logo-wrap {
                display: flex;
                align-items: center;
                justify-content: start;
                margin-right: 10px;
                img {
                    width: 160px;
                    height: 48px;
                    object-fit: contain;
                }
                .select-zone {
                    font-size: 14px;
                    width: 80px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-left: 10px;
                    color: #131523;
                    .area-icon {
                        color: $systemThemeColor;
                        margin-right: 5px;
                    }
                }

                .title {
                    position: relative;
                    display: inline-block;
                    padding-left: 16px;
                    color: #a1a7c4;
                    font-size: 20px;
                    font-weight: 700;
                    letter-spacing: 0.83px;
                    max-width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 3px;
                        height: 3px;
                        background-color: #a1a7c4;
                        border-radius: 50%;
                        top: 12px;
                        left: 5px;
                    }
                }
            }
            .nav {
                flex: 1;
                display: flex;
                justify-content: flex-start;

                .nav-text {
                    letter-spacing: 0.58px;
                }
                .trigger {
                    font-size: 16px;
                    color: #131523;
                }
                .trigger:hover {
                    color: #fff;
                    background-color: $systemThemeColor;
                }
            }
            .tab-item {
                box-sizing: border-box;
                height: 100%;
                width: 100px;
                padding: 0;
                line-height: 72px;
                cursor: pointer;
                border-width: 0;
                text-align: center;
                transition: 0.1s;
                &:hover {
                    color: $systemThemeColor;
                }

                &.active {
                    background: $systemThemeColor;
                    color: #fff;
                }
            }
            .user-info {
                align-self: center;

                .info {
                    display: flex;
                    align-items: center;

                    .name {
                        display: inline-block;
                        margin: 0 5px;
                        max-width: 67px;
                    }

                    .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    .trigger {
                        transform-origin: left;
                        display: flex;
                        cursor: pointer;

                        .secondary-title {
                            font-size: 16px;
                        }

                        .down {
                            display: inline-block;
                            transform: scale(0.7);
                            em {
                                font-size: 10px;
                                color: #7e84a3;
                            }
                        }
                    }
                }
            }
        }
        :deep .el-dialog__header {
            border-bottom: 1px solid rgba(#000, 0.1);
        }
        :deep .el-dialog__body {
            padding-top: 20px;
            padding-bottom: 0;
            padding-left: 10px;
        }
        :deep .el-dialog__footer {
            padding-top: 0px;
        }
    }
    .zone-tree-wrap {
        .el-tree {
            margin-top: 20px;
            max-height: 600px;
            overflow: scroll;
            .el-tree-node {
                .el-tree-node__content {
                    height: 40px;
                }
                .el-tree-node__label {
                    margin-left: 10px;
                }
            }
        }
    }
</style>
