import { AuthType, setAuths } from '@/common/directives/auth'
import { RouteMeta, RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { RouterName, RouterPath } from './constant'

import Layout from '@/views/Layout.vue'
import Login from '@/views/Login.vue'
import { defineComponent } from 'vue'
import { routerBeforeEach } from './router-guard'

export type Component<T extends keyof any = any> =
    | ReturnType<typeof defineComponent>
    | (() => Promise<typeof import('*.vue')>)
    | (() => Promise<T>)

declare type Recordable<T = any> = Record<string, T>
export interface AppRouteRecordRaw extends Omit<RouteRecordRaw, 'meta'> {
    name: string
    path: string
    component: Component | string
    meta?: RouteMeta
    components?: Component
    props?: Recordable
    fullPath?: string
}

let isLoadMenus = false

let authRoutes: any[] = []

// 登录、错误页面等不需要被路由保护
const baseRoutes: Array<AppRouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: Login
    }
]

// 菜单路由，layout下的路由
const menuRoutes: Array<AppRouteRecordRaw> = [
    {
        name: RouterName.HOME,
        path: RouterPath.HOME,
        component: () => import('@/views/home/Home.vue'),
        children: [],
        meta: {
            label: '首页',
            isShowBanner: true,
            isMenuTab: true,
            alias: AuthType.HOME
        }
    },
    {
        name: RouterName.PROJECT_LIST,
        path: RouterPath.PROJECT_LIST,
        component: () => import('@/views/home/NewsList.vue'),
        meta: {
            label: '活动项目',
            defaultBannerName: 'project',
            isMenuTab: true,
            alias: AuthType.HOME
        }
    },
    {
        name: RouterName.PROJECT_LIST,
        path: RouterPath.PROJECT_LIST,
        component: () => import('@/views/project/index.vue'),
        meta: {
            label: '项目列表',
            defaultBannerName: 'about'
        }
    },
    {
        name: RouterName.PROJECT_DETAIL,
        path: RouterPath.PROJECT_LIST + '/' + ':id',
        component: () => import('@/views/project/detail.vue'),
        meta: {
            label: '项目详情',
            defaultBannerName: 'about'
        }
    },
    {
        name: RouterName.FAMOUS_TEACHER,
        path: RouterPath.FAMOUS_TEACHER,
        component: () => import('@/views/home/TeacherList.vue'),
        meta: {
            label: '专家团队',
            defaultBannerName: 'about'
        }
    },
    {
        name: RouterName.FAMOUS_STUDENT,
        path: RouterPath.FAMOUS_STUDENT,
        component: () => import('@/views/home/StudentList.vue'),
        meta: {
            label: '优秀学生列表',
            defaultBannerName: 'about'
        }
    },
    {
        name: RouterName.SCHOOL_LIST,
        path: RouterPath.SCHOOL_LIST,
        component: () => import('@/views/home/SchoolList.vue'),
        meta: {
            label: '高校链接',
            defaultBannerName: 'about'
        }
    },
    {
        name: RouterName.TEACHER_DETAIL,
        path: RouterPath.FAMOUS_TEACHER + '/' + ':id',
        component: () => import('@/views/home/TeacherDetail.vue'),
        meta: {
            label: '专家介绍',
            defaultBannerName: 'about'
        }
    },
    {
        name: RouterName.STUDENT_DETAIL,
        path: RouterPath.FAMOUS_STUDENT + '/' + ':id',
        component: () => import('@/views/home/StudentDetail.vue'),
        meta: {
            label: '学生介绍',
            defaultBannerName: 'about'
        }
    },
    {
        name: RouterName.NEWS_LIST,
        path: RouterPath.NEWS_LIST,
        component: () => import('@/views/home/NewsList.vue'),
        meta: {
            label: '活动资讯',
            defaultBannerName: 'news',
            isMenuTab: true,
            alias: AuthType.HOME
        }
    },
    {
        name: RouterName.CENTER_APPLY,
        path: RouterPath.CENTER_APPLY,
        component: () => import('@/views/home/NewsList.vue'),
        meta: {
            label: '活动报名',
            isMenuTab: true,
            alias: AuthType.HOME
        }
    },
    {
        name: RouterName.INFO_DOWNLOAD,
        path: RouterPath.INFO_DOWNLOAD,
        component: () => import('@/views/home/NewsList.vue'),
        meta: {
            label: '信息单下载',
            isMenuTab: true,
            alias: AuthType.HOME
        }
    },
    {
        name: RouterName.GRADES,
        path: RouterPath.GRADES,
        component: () => import('@/views/home/NewsList.vue'),
        meta: {
            label: '成绩查询',
            isMenuTab: true,
            alias: AuthType.HOME
        }
    },
    {
        name: RouterName.PERSONAL_CENTER,
        path: RouterPath.PERSONAL_CENTER,
        component: () => import('@/views/home/NewsList.vue'),
        meta: {
            label: '个人中心',
            isMenuTab: true,
            alias: AuthType.HOME
        }
    },
    {
        name: RouterName.NEWS_DETAIL,
        path: RouterPath.NEWS_LIST + '/' + ':id',
        component: () => import('@/views/home/NewsDetail.vue'),
        meta: {
            label: '详情',
            defaultBannerName: 'news'
        }
    },
    // 关于我们
    {
        name: RouterName.ABOUT_US,
        path: RouterPath.ABOUT_US,
        component: () => import('@/views/about/index.vue'),
        meta: {
            label: '关于我们',
            defaultBannerName: 'about',
            isMenuTab: true,
            alias: AuthType.HOME
        }
    }
]

const routes: Array<AppRouteRecordRaw> = [
    ...baseRoutes,
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/home',
        children: menuRoutes
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
    routerBeforeEach(to, from, next)
})

export default router
