<!--
 * @Author: liujian
 * @Description: 
 * @Date: 2021-09-06 17:46:34
 * @LastEditTime: 2023-09-26 16:21:33
 * @FilePath: /three-class-fe-1/src/components/common/banner/bannerCommon.vue
-->
<template>
    <div :class="['banner-wrap', $route.meta.defaultBannerName ? 'second' : '']">
        <div class="manage-banner" v-if="$route.meta.isShowBanner">
            <div v-if="state.routerName === state.routerNames.FAMOUS_COURIER">
                <CourierBanner></CourierBanner>
            </div>
            <div
                v-if="state.bannerList.length > 0"
                :key="state.carouselKey"
                :class="['banner-carousel', state.bannerList.length === 1 ? 'hide-indicators' : '']"
            >
                <ElCarousel autoplay :initial-index="0" height="420px" ref="bannerCarouselRef">
                    <ElCarouselItem v-for="item in state.bannerList" :key="item.id">
                        <img :src="item.cover_url" alt="banner" @click="handleBannerClick(item)" style="cursor: pointer;" :title="item.project_title"/>
                    </ElCarouselItem>
                </ElCarousel>
            </div>
            <div class="banner-carousel" v-if="state.defaultImagePath && state.bannerList.length === 0">
                <img :src="state.defaultImagePath" alt="" />
            </div>
        </div>
        <div v-else>
            <img :src="getDefaultBannerImg($route.meta.defaultBannerName)" alt="" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { onMounted, reactive, watch } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import CourierBanner from './courierBanner.vue'
    import { getListBanner } from '@/service/api/common'
    import { BannerEnum } from '@/common/enums/banner-enum'
    import { RouterName } from '@/router/constant'
    import defaultResourceImagePath from '@/assets/img/default/banner-default-resource.png'
    import defaultAbout from '@/assets/img/banner/about.jpg'
    import { openNewPage } from '@/common/hooks/blankNewPage'

    const router = useRouter()

    const route = useRoute()

    const state = reactive({
        bannerList: [] as any[],
        routerName: RouterName.HOME,
        routerNames: RouterName,
        defaultImagePath: '',
        carouselKey: 0,
        currentYear: new Date().getFullYear(),
        label: '',
    })

    /**
     * 加载banner数据
     */
    const initBannerList = async (bannerType: BannerEnum, routerName: RouterName, imagePath: string) => {
        state.routerName = routerName
        try {
            const res =  await getListBanner({ position_type: bannerType })
            state.bannerList = res
            if (res.length === 0) {
                state.defaultImagePath = imagePath
            }
        } catch (error) {
            state.defaultImagePath = imagePath
            state.bannerList = []
        }
        state.carouselKey++
    }

    /**
     * banner图片
     */
    const routeChange = (): void => {
        const currentRouteName = router.currentRoute.value.name
        state.label = route.meta.label || ''
        switch (currentRouteName) {
            case RouterName.HOME:
                initBannerList(BannerEnum.Home, RouterName.HOME, defaultResourceImagePath)
                break
            case RouterName.RESOURCE_CENTER:
                initBannerList(BannerEnum.ResourceCenter, RouterName.RESOURCE_CENTER, defaultResourceImagePath)
                break
            case RouterName.ABOUT_US:
                initBannerList(BannerEnum.OpenLive, RouterName.OPEN_LIVE, defaultAbout)
                break
        }
    }

    /**
     * 路由变化，变更banner
     */
    watch(
        () => route.fullPath,
        () => {
            routeChange()
        },
        { immediate: true }
    )
    /**
     * 获取默认的banner图
     */
    const getDefaultBannerImg = (bannerImgName: string | unknown) => {
        const path = `../../../assets/img/banner/${bannerImgName}.jpg`
        const modules = import.meta.globEager('../../../assets/img/banner/*.jpg')
        return modules[path].default
    }

    /**
     * banner点击事件
     */
    const handleBannerClick = async (item: any) => {
        if(item.sign_up_url) {
            const url = location.origin + item.sign_up_url
            openNewPage(url)
            return
        }
        else if (item.project_id) {
        router.push({ name: RouterName.PROJECT_DETAIL, params: { id: item.project_id } })
    }
        
    }
    onMounted(() => {
        router.options.routes[1].children?.forEach((item: any) => {
            if (item.name === state.routerName) {
                state.label = item.meta.label
            }
        })
    })
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: "pangmanzhengdao";
        src: url("../../../assets/fonts2/PangMenZhengDaoBiaoTiTi-1.ttf");
    }
    .banner-wrap {
        width: 100%;
        height: 400px;
        min-height: 420px;
        display: flex;
        position: relative;
        margin: 0 auto;
        font-size: 60px;

        &.second {
            max-height: 240px;
            min-height: 240px;
        }
        &.second img,.banner-carousel img {
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            max-width: 100%;
            transform: translateX(-50%);
            object-fit: contain;
        }
        

        .manage-banner {
            width: 100%;
            position: relative;
            .banner-text {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 56px;
                font-weight: 400;
                color: #ffffff;
                .banner-text-content {
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    justify-content: center;
                    .banner-logo {
                        width: 55px;
                        height: 45px;
                        margin-right: 10px;
                    }
                }
                .banner-text-title {
                    font-weight: 400;
                }
                .home-banner {
                    text-align: center;
                    .banner-text-content {
                        justify-content: center;
                    }
                    .text-p1 {
                        font-size: 70px;
                    }
                    .text-p2 {
                        font-size: 46px;
                    }
                    .text-p3 {
                        font-size: 20px;
                        letter-spacing: 1px;
                    }
                    .text-p3::before {
                        content: '';
                        display: inline-block;
                        width: 100px;
                        height: 1px;
                        background: #ffffff;
                        border-radius: 50%;
                        margin-right: 20px;
                        margin-bottom: 6px;
                    }
                    .text-p3::after {
                        content: '';
                        display: inline-block;
                        width: 100px;
                        height: 1px;
                        background: #ffffff;
                        border-radius: 50%;
                        margin-left: 20px;
                        margin-bottom: 6px;
                    }
                }
                .courier-text {
                    letter-spacing: 2px;
                    font-weight: 300;
                    .text-p2 {
                        font-size: 42px;
                    }
                    span.pmzd-text {
                        margin-left: 25px;
                    }
                }
                .pmzd-text {
                    font-family: 'pangmanzhengdao';
                }
            }
            .banner-text.left {
                left: 10%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .banner-carousel {
            height: 100%;
            flex: 1;
            &.hide-indicators {
                :deep .el-carousel__indicators {
                    display: none;
                }
            }
        }

        @media screen and (min-width: 2560px) {
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
</style>