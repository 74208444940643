/*
 * 专递课堂相关api
 */
import { CourseTypeEnum } from '@/common/enums/course-type-enum'
import * as service from '../index'

import { BannerPairGroupStatisticsInfo, BannerStatisticsInfo } from './types'

// 获取结对组列表
type GetSchoolGroup = {
    filter_key?: string
    city_id?: number
    area_id?: number
    page: number
    page_size: number
}

// 获取结对组课程列表
export type GetCourseListInfo = {
    filter_key?: string
    start_time?: string
    end_time?: string
    city_id?: string
    area_id?: string
    school_group_id?: string
    status?: number
    grade_period_id?: number
    page: number
    page_size: number
    subject_id?: number
    type?: number
}

// 课程、老师、学校排行榜
type GetRankSizeInfo = {
    size: number
}

// 校校结对
type AreaTotalInfo = {
    filter_key?: string
    city_id?: number
    area_id?: number
    page: number
    page_size: number
}

// banner旁的统计接口按的city_id
type CourierBannerInfo = {
    city_id?: number
}

// 获取区/县列表
type GetAreaList = {
    city_id: number
}

// 结对组详情
type SchoolGroupId = {
    school_group_id: number
}

// 课程直播、回放链接
type CourseLiveRoomId = {
    room_id: number,
    is_mobile: number
}

/**
 * 专递课程
 */
export function getSchoolGroup(params: GetSchoolGroup): Promise<any> {
    return service.get('/web/course/getList', params)
}

/**
 * 结对组：课程列表
 */
export function getCourseList(params: GetCourseListInfo): Promise<any> {
    return service.get('/web/course/getList', params)
}

/**
 * 课程统计
 */
export function getCourseStatistical(): Promise<any> {
    return service.get('/web/school_group/courseStatistical')
}

/**
 * 校校结对
 */
export function getAreaList(params: AreaTotalInfo): Promise<any> {
    return service.get('/web/school_group/list', params)
}

/**
 * 专递课堂-活跃结对组
 */
export function getCourseRanking(params: GetRankSizeInfo = { size: 5 }): Promise<any> {
    return service.get('/web/school_group/courseRanking', params)
}

/**
 * 专递课堂-活跃学校
 */
export function getZoneRanking(params: GetRankSizeInfo = { size: 5 }): Promise<any> {
    return service.get('/web/school_group/zoneRanking', params)
}

/**
 * 专递课堂-活跃教师
 */
export function getTeacherRanking(params: GetRankSizeInfo = { size: 5 }): Promise<any> {
    return service.get('/web/school_group/teacherRanking', params)
}

/**
 * 专递-banner城市列表（之后拓展成可选择）
 */
export function getCityList(params?: {}): Promise<any> {
    return service.get('/web/school_group/getCityList', params)
}

export function getGroupAreaList(params: GetAreaList): Promise<any> {
    return service.get('/web/school_group/getAreaList', params)
}

/**
 * 结对组详情
 */
export function getGropuDetail(params: SchoolGroupId): Promise<any> {
    return service.get('/web/school_group/detail', params)
}

/**
 * 结对组 活跃学校
 */
export function getZoneRankingWithSchool(params: SchoolGroupId): Promise<any> {
    return service.get('/web/school_group/zoneRankingWithSchoolGroupId', params)
}

/**
 * 课表-获取课程直播信息
 */
export function getLivingRoomUrl(params: CourseLiveRoomId, courseType: CourseTypeEnum): Promise<any> {
    if (
        courseType === CourseTypeEnum.SchoolPushLive ||
        courseType === CourseTypeEnum.CourierPushLive ||
        courseType === CourseTypeEnum.TeacherCommentPushLive ||
        courseType === CourseTypeEnum.TeacherResearchPushLive
    ) {
        return service.get('/web/course/getBjyLivingRoomUrl', params)
    } else {
        return service.get('/web/course/getLivingRoomUrl', params)
    }
}

/**
 * banner旁统计接口整合
 */
export function courierStatistics(params?: { city_id: 0 }): Promise<BannerStatisticsInfo> {
    return service.get('/web/school_group/bannerStatistical', params)
}

/**
 * 校校结对详情统计
 */
export function courseStatisticalWithGroupId(params: SchoolGroupId): Promise<BannerPairGroupStatisticsInfo> {
    return service.get('/web/school_group/courseStatisticalWithGroupId', params)
}
