/*
 * @Author: liujian
 * @Description:
 * @Date: 2022-05-16 15:57:47
 * @LastEditTime: 2023-07-25 15:20:56
 * @LastEditors: Please set LastEditors
 * @FilePath: /three-class-fe-1/src/common/directives/auth.ts
 */

import { ObjectDirective } from 'vue'

// 前台需要配置的权限：专递、名师、名校、资源
let auths: any[] = []

export enum AuthType {
    HOME = 'home',
    COURIER = 'courierClassroom',
    TEACHER = 'teacher',
    SCHOOL = 'school',
    RESOURCE = 'resourceManagement',
    LIVE = 'liveCourse',
    RECORD = 'recordCourse',
    AIR = 'airClass',
    OPEN_LIVE = 'openLive',
    NEWS = 'news',
}

export const setAuths = (authArr: any[]) => {
    auths = authArr
}

function isAuth(el: Element, binding: any) {
    const value = binding.value
    const hasAuth = auths.some((e: any) => e.alias === value && e.status)
    if (!hasAuth) {
        el.parentNode && el.parentNode.removeChild(el)
    }
}

const auth: ObjectDirective = {
    mounted(el, binding) {
        isAuth(el, binding)
    }
}

export default auth
