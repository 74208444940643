/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-02 13:54:37
 * @LastEditTime: 2022-03-01 16:49:10
 * @FilePath: /three-class-fe-1/src/service/api/login.ts
 */
import * as service from '../index'

type LoginInfo = {
    account: number | string
    password: string
}

type LoginRes = {
    user_id: number
    partner_id: number
    role: number
    real_name: string
    avatar: string
}

type VerifyLogin = {
    is_login: boolean
}

/**
 * 登录
 */
export function loginVerify(params: LoginInfo): Promise<LoginRes> {
    return service.post('/account/login', params)
}

/**
 * 退出登录
 */
export function logout(): Promise<boolean> {
    return service.post('/account/logout')
}

/**
 * 验证是否登录
 */
export function isLogin(): Promise<VerifyLogin> {
    return service.get('/account/isLogin')
}
