/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-13 14:25:13
 * @LastEditTime: 2022-02-28 10:08:48
 * @FilePath: /three-class-fe-1/src/router/router-guard.ts
 */
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RouterName, RouterPath } from '@/router/constant'

import { LOCAL_USER_INFO } from '@/config/constant'
import { useUserStoreWithOut } from '@/store/modules/user'

// 路由白名单
const ROUTE_NAME_WHITE_LIST = [
    RouterName.LOGIN,
    RouterName.HOME,
    RouterName.FAMOUS_COURIER,
    RouterName.FAMOUS_COURIER_WORKPAIRS,
    RouterName.FAMOUS_COURIER_AREA_PAIR,
    RouterName.FAMOUS_COURIER_REGIONAL_PAIRING_DETAIL,
    RouterName.FAMOUS_TEACHER,
    RouterName.FAMOUS_TEACHER_TEACHER_RESEARCH,
    RouterName.FAMOUS_TEACHER_COMMENT,
    RouterName.FAMOUS_SCHOOL,
    RouterName.FAMOUS_SCHOOL_EXCELLENT_COURSE_LIST,
    RouterName.FAMOUS_TEACHER_STUDIO,
    RouterName.FAMOUS_TEACHER_STUDIO_DETAIL,
    RouterName.NEWS_LIST,
    RouterName.NEWS_DETAIL,
    RouterName.RESOURCE_CENTER,
    RouterName.RESOURCE_DETAIL,
    RouterName.FAMOUS_SCHOOL_EXCELLENT_COURSE_DEMAND_LIST
]

export const routerBeforeEach = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const userStore = useUserStoreWithOut()
    const userInfo = localStorage.getItem(LOCAL_USER_INFO)
    // 刷新store用户信息丢失
    if (!userStore.getUserInfo.userId && to.path !== '/' + RouterPath.LOGIN && userInfo && JSON.parse(userInfo).userId) {
        userStore.setUserInfo(JSON.parse(userInfo))
    }
    const index = ROUTE_NAME_WHITE_LIST.findIndex((e) => e === to.name)
    // 浏览器返回限制
    if (!userInfo && index === -1) {
        next({ path: RouterPath.HOME })
        return
    }
    next()
}
