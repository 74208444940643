import { Router } from 'vue-router'
/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-13 15:15:57
 * @LastEditTime: 2022-02-23 16:29:25
 * @FilePath: /three-class-fe-1/src/common/hooks/courseAuthVerify.ts
 */
import { RouterName } from '@/router/constant'
import { isLogin } from '@/service/api/login'
import { useLoginPopupStoreWithOut } from '@/store/modules/loginPopup'

export const useCourseAuthVerify = async (
    routerName: RouterName,
    params: {},
    router: Router,
    courseId?: string,
    isFromLogin: boolean = false
) => {
    if (!isFromLogin) {
        const loginPopupStore = useLoginPopupStoreWithOut()
        const loginRes = await isLogin()
        // 没有登录
        if (!loginRes) {
            loginPopupStore.setShowLoginPopup(true)
            loginPopupStore.setWillPathName(routerName)
            loginPopupStore.setWillPathParams(params)
            return
        }
        if (!courseId) {
            routeToTarget(routerName, params, router)
            return
        }
    }
    if (courseId) {
        routeToTarget(routerName, params, router)
    }
}

const routeToTarget = (routerName: RouterName, params: {}, router: Router) => {
    router.push({
        name: routerName,
        params
    })
}
