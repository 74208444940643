<!--
 * @Author: liujian
 * @Description: 
 * @Date: 2021-08-30 14:28:03
 * @LastEditTime: 2023-09-15 09:41:32
 * @FilePath: /three-class-fe-1/src/views/Layout.vue
-->
<template>
    <div class="bjy-layout">
        <MenuTab /><Banner v-if="$route.meta.isShowBanner || $route.meta.defaultBannerName" />
        <div class="container-wrap">
            <Breadcrumb v-if="$route.meta.isShowBreadCrumb" /><router-view />
        </div>
        <LoginPopup
            v-if="state.loginPopupStore.showLoginPopup"
            :will-route-name="state.loginPopupStore.willPathName"
        />
        <!-- <AboutUs/> -->
        <Footer />
    </div>
</template>

<script lang="ts" setup>
    import { reactive } from 'vue'
    import Banner from '@/components/common/banner/bannerCommon.vue'
    import MenuTab from '../components/common/menuTab.vue'
    import LoginPopup from '@com/common/LoginPopup.vue'
    import Breadcrumb from '@com/share/breadcrumb.vue'
    import { useLoginPopupStoreWithOut } from '@/store/modules/loginPopup'
    import Footer from '@/components/common/footer.vue'
    // import AboutUs from './about/index.vue'

    const loginPopupStore = useLoginPopupStoreWithOut()
    const state = reactive({
        loginPopupStore
    })
</script>

<style lang="scss" scoped>
    .bjy-layout {
        width: 100%;
        display: flex;
        flex-direction: column;
        .top {
            height: 72px;
        }
        .container-wrap {
            position: relative;
            flex: 1;
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 16px;
        }
    }
</style>
