/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-08-30 15:42:43
 * @LastEditTime: 2023-09-12 11:21:30
 * @FilePath: /three-class-fe-1/src/service/api/common.ts
 */
import * as service from '../index'

import { Subject } from './types'

type BannerType = {
    value: string
}

/**
 * 获取banner列表
 */
export function getListBanner(params: any): Promise<BannerType[]> {
    return service.get('/web/banner/list', params)
}

/**
 * 获取学科
 */
export function getAllSubjects(params?: any): Promise<Subject[]> {
    return service.get('/web/subject/list', params)
}

/**
 * 获取学段/年级/学科树
 */
export function getSubjectTree(): Promise<any> {
    return service.get('/web/subject/getTreeList')
}

/**
 * 获取系统logo
 */
export function getSystemLogo(): Promise<any> {
    return service.get('/web/door/getLogo')
}

/**
 * 获取系统名称
 * @returns
 */
export function getSystemName(): Promise<any> {
    return service.get('/web/door/getPlatformName')
}

/**
 * 获取学段
 */
export function getPeriodList(): Promise<any> {
    return service.get('/web/period/list')
}

/**
 * 获取组织架构树
 */
export function getZoneListTree(): Promise<any> {
    return service.get('/web/zone/tree')
}
