/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-08-08 23:17:21
 * @LastEditTime: 2021-09-26 16:39:39
 * @FilePath: /three-class-fe-1/src/store/index.ts
 */
import { createPinia } from 'pinia'

const store = createPinia()

export { store }
