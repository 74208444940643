/*
 * @Author: qian
 * @Date: 2023-09-12 17:35:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-19 15:01:15
 * @Description: 
 */
/**
 * @description 手机号验证
 * @param rule
 * @param value
 * @param callback
 */
export const phoneNumber = function (rule: any, value: any, callback: Function) {
	if (!value) {
		return callback(new Error('请输入手机号'))
	} else if (!/^1[3456789]\d{9}$/.test(value)) {
		return callback(new Error('请输入格式正确的手机号'))
	} else {
		callback()
	}
}

/**
 * @description 纯数字严重
 * @param rule
 * @param value
 * @param callback
 */
export const number = function (rule: any, value: any, callback: Function) {
	if (!value) {
		return callback(new Error('请输入学号'))
	} else if (!/^\d*$/.test(value)) {
		return callback(new Error('请输入格式正确的学号号'))
	} else {
		callback()
	}
}

/** 判断是否为第二代身份证（18 位） */
export const isChineseIdCard = (rule: any, str: any, callback: Function) => {
	const reg = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
	if (!str) {
		return callback(new Error('请输入身份证号'))
	}
	else if (!reg.test(str)) {
		// return callback(new Error('请输入格式正确的身份证号'))
		callback()
	}
	else {
		callback()
	}
}

/**
 * 密码验证
 * @param rule
 * @param value
 * @param callback
 */
export const passwordValid = function (rule: any, value: any, callback: Function) {
	if (!value) {
		return callback(new Error('请输入密码'))
	} else if (!/^[0-9]{6,12}$/g.test(value)) {
		return callback(new Error('密码长度在6-12位，并且为纯数字'))
	} else {
		callback()
	}
}

/**
 * 
 */
export const accountValid = function (rule: any, value: any, callback: any) {
	if (!value) {
		return callback(new Error('请输入账号'))
	} else if (!/^[a-zA-Z\d]{1,}$/g.test(value)) {
		return callback(new Error('账号只能包含字母或数字'))
	} else {
		callback()
	}
}


/**
 * 图片验证码验证
 * @param rule
 * @param value
 * @param callback
 */
export const imgCodeValid = function (rule: any, value: any, callback: Function) {
	if (!value) {
		return callback(new Error('请输入验证码'))
	}
}

/**
 * @description 直播课程 课节时间段有效性验证
 * @param rule
 * @param value Array
 * @param callback
 */
export const liveCourseTimeRangeValid = function (rule: any, value: any, callback: Function) {
	if (!value[0]) {
		return callback(new Error('请设置开始时间'))
	} else if (!value[1]) {
		return callback(new Error('请设置结束时间'))
	} else if (value[1] <= value[0]) {
		return callback(new Error('结束时间必须晚于开始时间'))
	} else {
		callback()
	}
}

/**
 * @description 课次管理时间日期验证
 * @param rule
 * @param value
 * @param callback
 */
export const lessonDateRangeValid = function (rule: any, value: any, callback: Function) {
	if (!value[0] || !value[1]) {
		return callback(new Error('请选择开始时间和结束时间'))
	} else {
		callback()
	}
}

/**
 * 创建课程常用校验
 */

export const rulesValid = {
	name: [
		{ required: true, message: '请填写课程名称', trigger: 'blur' },
		{
			required: true,
			message: '请填写课程名称',
			trigger: 'change'
		}
	],
	teacher_name: [
		{ required: true, message: '请填写活动主题', trigger: 'blur' },
		{
			required: true,
			message: '请填写活动主题',
			trigger: 'change'
		}
	],
	main_zone_id: [
		{ required: true, message: '请选择主讲学校', trigger: 'blur' },
		{ required: true, message: '请选择主讲学校', trigger: 'change' }
	],
	teacher_main_zone_id: [
		{ required: true, message: '请选择主办学校', trigger: 'blur' },
		{ required: true, message: '请选择主办学校', trigger: 'change' }
	],
	main_device_id: [
		{ required: true, message: '请选择主讲教室', trigger: 'blur' },
		{
			required: true,
			message: '请选择主讲教室',
			trigger: 'change'
		}
	],
	teacher_main_device_id: [
		{ required: true, message: '请选择主办教室', trigger: 'blur' },
		{
			required: true,
			message: '请选择主办教室',
			trigger: 'change'
		}
	],
	main_teacher_id: [
		{ required: true, message: '请选择主讲老师', trigger: 'blur' },
		{
			required: true,
			message: '请选择主讲老师',
			trigger: 'change'
		}
	],
	subjectTree: [
		{ required: true, message: '请选择学段年级科目', trigger: 'blur' },
		{
			required: true,
			message: '请选择学段年级科目',
			trigger: 'change'
		}
	],
	course_date: [
		{ required: true, message: '请选择上课日期', trigger: 'change' },
		{ required: true, message: '请选择上课日期', trigger: 'blur' }
	],
	weeks: [
		{ required: true, message: '请至少选择一天', trigger: 'blur' },
		{
			required: true,
			message: '请至少选择一天',
			trigger: 'change'
		}
	],
	ss_ids: [
		{ required: true, message: '请选择评分项', trigger: 'blur' },
		{
			required: true,
			message: '请选择评分项',
			trigger: 'change'
		}
	]
}


export const  checkPhone =function(rule:any, value:any, callback:any) {
      const regwhitename = /^1[3456789]\d{9}$/
      if (!value) { //所以当没有值的时候，我们直接callback，让他不校验直接执行下一步
        return callback(new Error('请输入手机号码'))
      }
	  else{
        if (regwhitename.test(value)) {  //验证通过也下一步
           return callback();
        }else{
          callback(new Error("请输入正确格式的手机号码")); //验证不通过下一步只是抛出个错误
        }
      }
    }