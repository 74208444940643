import { RouterName } from '@/router/constant'
/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-02 16:57:34
 * @LastEditTime: 2022-03-01 16:48:27
 * @FilePath: /three-class-fe-1/src/store/modules/loginPopup.ts
 */
import { defineStore } from 'pinia'
import { store } from '../index'

export interface LoginPopupInfo {
    showLoginPopup: boolean
    willPathName: RouterName
    willPathParams: {}
    courseId: string
}

export const useLoginPopupStore = defineStore({
    id: 'app-login-popup',
    state: (): LoginPopupInfo => ({
        showLoginPopup: false,
        willPathName: RouterName.FAMOUS_COURIER,
        willPathParams: {},
        courseId: ''
    }),
    actions: {
        setShowLoginPopup(show: boolean) {
            this.showLoginPopup = show
        },
        setWillPathName(willPathName: RouterName) {
            this.willPathName = willPathName
        },
        setWillPathParams(willPathParams: {}) {
            this.willPathParams = willPathParams
        },
        setCourseId(courseId: string) {
            this.courseId = courseId
        },
        clearLoginPopupInfo() {
            this.setShowLoginPopup(false)
            this.setWillPathName(RouterName.FAMOUS_COURIER)
            this.setWillPathParams({})
            this.setCourseId('')
        }
    },
    getters: {
        getShowLoginPopup(): boolean {
            return this.showLoginPopup
        },
        getWillPathName(): RouterName {
            return this.willPathName
        },
        getWillPathParams(): {} {
            return this.willPathParams
        },
        getCourseId(): string {
            return this.courseId
        }
    }
})

export function useLoginPopupStoreWithOut() {
    return useLoginPopupStore(store)
}
