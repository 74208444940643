import { LOCAL_USER_INFO } from '@/config/constant'
/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-02 16:57:34
 * @LastEditTime: 2022-02-28 10:03:24
 * @FilePath: /three-class-fe-1/src/store/modules/user.ts
 */
import { defineStore } from 'pinia'
import { store } from '../index'
export class UserInfo {
    realName: string = ''
    role: number = 0
    userId: number = 0
    avatar: string = ''
    isAdmin: boolean = false
    isManage: boolean = false
    isStudent: boolean = false
    isTeacher: boolean = false
}
interface LocalState {
    userInfo: UserInfo
}

export const useUserStore = defineStore({
    id: 'user',
    state: (): LocalState => ({
        userInfo: new UserInfo()
    }),
    actions: {
        setUserInfo(userInfo: UserInfo) {
            this.userInfo = userInfo
            localStorage.setItem(LOCAL_USER_INFO, JSON.stringify(userInfo))
        }
    },
    getters: {
        getUserInfo(): UserInfo {
            return this.userInfo
        }
    }
})

export function useUserStoreWithOut() {
    return useUserStore(store)
}
