/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-10-08 14:50:16
 * @LastEditTime: 2022-05-16 16:35:05
 * @FilePath: /three-class-fe-1/src/common/directives/clickOutside.ts
 */

import type { DirectiveBinding, ObjectDirective } from 'vue'

import { isServer } from '@/utils/is'
import { on } from '@/utils/domUtils'

type DocumentHandler = <T extends MouseEvent>(mouseup: T, mousedown: T) => void

type FlushList = Map<
    HTMLElement,
    {
        documentHandler: DocumentHandler
        bindingFn: (...args: unknown[]) => unknown
    }
>

const nodeList: FlushList = new Map()

let startClick: MouseEvent

if (!isServer) {
    on(document, 'mousedown', (e: any) => (startClick = e))
    on(document, 'mouseup', (e: any) => {
        for (const { documentHandler } of nodeList.values()) {
            documentHandler(e, startClick)
        }
    })
}

function createDocumentHandler(el: HTMLElement, binding: DirectiveBinding): DocumentHandler {
    let excludes: HTMLElement[] = []
    if (Array.isArray(binding.arg)) {
        excludes = binding.arg
    } else {
        excludes.push(binding.arg as unknown as HTMLElement)
    }
    return function (mouseup, mousedown) {
        const instance: any = binding.instance
        const popperRef = instance.popperRef
        const mouseUpTarget = mouseup.target as Node
        const mouseDownTarget = mousedown.target as Node
        const isBound = !binding || !binding.instance
        const isTargetExists = !mouseUpTarget || !mouseDownTarget
        const isContainedByEl = el.contains(mouseUpTarget) || el.contains(mouseDownTarget)
        const isSelf = el === mouseUpTarget

        const isTargetExcluded =
            (excludes.length && excludes.some((item) => item?.contains(mouseUpTarget))) ||
            (excludes.length && excludes.includes(mouseDownTarget as HTMLElement))
        const isContainedByPopper = popperRef && (popperRef.contains(mouseUpTarget) || popperRef.contains(mouseDownTarget))
        if (isBound || isTargetExists || isContainedByEl || isSelf || isTargetExcluded || isContainedByPopper) {
            return
        }
        binding.value()
    }
}

const ClickOutside: ObjectDirective = {
    beforeMount(el, binding) {
        setNodeList(el, binding)
    },
    updated(el, binding) {
        setNodeList(el, binding)
    },
    unmounted(el) {
        nodeList.delete(el)
    }
}

function setNodeList(el: any, binding: any) {
    nodeList.set(el, {
        documentHandler: createDocumentHandler(el, binding),
        bindingFn: binding.value
    })
}

export default ClickOutside
