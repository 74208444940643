<!--
 * @Author: qian
 * @Date: 2023-09-04 09:38:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-25 17:49:32
 * @Description: 
-->
<template>
    <div class="footer-wrap">
        <div class="footer-content">
            <div class="menu-tabs">
                <div class="menu-tabs-item">
                    <!-- <span v-for="item in state.menuList" @click="toMenu(item)">{{ item.name }}</span> -->
                </div>
                <div class="connect-us">
                    <span> 联系人：丁老师 010-62205108</span>
                    <span>高老师 010-62200406</span>
                    <span>尹老师 010-62200299</span>
                    <span>王老师 010-62202861</span>
                </div>
                <div class="connect-us">
                    <span> 地址：北京市海淀区文慧园北路8号</span><span>邮箱：qgzxsslh@163.com</span>
                </div>
            </div>
            <div>
                <el-popover placement="left" width="110" trigger="hover">
                    <template #reference>
                        <img src="../../assets/img/index/footer-weixin-new.jpeg" style="width: 120px;" alt="">
                    </template>
                        <p>微信号名称：京师数理化生综合实践活动</p>
                </el-popover>
            </div>
        </div>
        <p>
            Copyright © 2018 zicai Corporation, All Rights Reserved  北京梓才科技有限公司 版权所有 京ICP备17032035号-1 京公网安备 11010802024362号
        </p>
    </div>
</template>
<script setup lang="ts">
import { reactive } from 'vue'
import { useRouter, useRoute, RouteRecordRaw } from 'vue-router'
import { RouterName, RouterPath } from '@/router/constant'
const router = useRouter()
const route = useRoute()
type MenuTab = {
    name: string | unknown
    path: string
    order: number | unknown
}
const state = reactive({
    menuList: [] as  MenuTab[]
})
/**
 * 根据tab跳转
 */
const toMenu = (menu: any = {}) => {
    let url = ''
     if (menu.path === RouterPath.CENTER_APPLY) {
        url = location.origin + '/' + 'personal-center/competitionDetail'
    }
    else if (menu.path === RouterPath.COMPETITION) {
        url = location.origin + '/' + 'personal-center/grades'
    }
    else if (menu.path === RouterPath.PERSONAL_CENTER) {
        url = location.origin + '/' + 'personal-center/information'
    }
    else {
        url = location.origin + '/webpage' + menu.path
        router.push('/' + menu.path)
    }
    window.open(url)
}
/**
 * 初始化导航列表
 */
const initTabMenuList = () => {
    state.menuList = getTabMenuList(router.options.routes)
}

/**
 * 获取导航菜单
 */
const getTabMenuList = (routes: RouteRecordRaw[], result: MenuTab[] = []) => {
    for (const element of routes) {
        const item = element
        if (item.meta?.isMenuTab) {
            result.push({
                name: item.meta.label,
                path: item.path,
                order: item.meta.order
            })
        }
        if (item.children) {
            getTabMenuList(item.children, result)
        }
    }
    result = result.sort((a: any, b: any) => a.order - b.order)
    return result
}
initTabMenuList()
</script>
<style lang="scss" scoped>
.footer-wrap {
    background-color: #444855;
    height: 235px;
    width: 100%;
    color: #b7b7b9;
    p {
        text-align: center;
    }
    .footer-content {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        padding: 25px 25px 0 25px;
        justify-content: space-between;
        align-items: center;
        .menu-tabs {
            // display: flex;
            color: #fff;
            padding-left: 40px;
            .menu-tabs-item, .connect-us {
                margin-bottom: 30px;
                span {
                    padding-right: 20px;
                    border-right: 2px solid #b7b7b9;
                    margin-right: 20px;
                    cursor: pointer;
                }
                span:last-child{
                    border: none;
                }
                span:hover {
                    // color: $systemThemeColor;
                }
            }
        }
        
        a {
            color: #b7b7b9;
            margin-right: 30px;
            cursor: pointer;
            text-decoration: none;
        }
        a:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}
</style>