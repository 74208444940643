import { Router } from 'vue-router'
/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-13 15:15:57
 * @LastEditTime: 2023-09-26 16:19:12
 * @FilePath: /three-class-fe-1/src/common/hooks/courseAuthVerify.ts
 */
import { RouterName } from '@/router/constant'
import { isLogin } from '@/service/api/login'
import { useLoginPopupStoreWithOut } from '@/store/modules/loginPopup'

export const openNewPage = async (
    url: string,
    params?: {},
    isFromLogin: boolean = false
) => {
    if (!isFromLogin) {
        const loginPopupStore = useLoginPopupStoreWithOut()
        const loginRes = await isLogin()
        // 没有登录
        if (!loginRes) {
            loginPopupStore.setShowLoginPopup(true)
            return
        }
        if (url) {
            routeToTarget(url)
            return
        }
    }
    if (url) {
        routeToTarget(url)
    }
}

const routeToTarget = (url: string, params?: {}) => {
    window.open(url)
}
