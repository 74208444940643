<template>
    <div class="courier-banner-wrap">
        <div class="courier-banner-item">
            <p class="courier-item-name">在线课程开设情况</p>
            <div class="team-courses">
                <p>
                    <span>{{ state.liveDeviceCount }}</span
                    ><span>主讲课堂</span>
                </p>
                <p class="two">
                    <span>{{ state.helpDeviceCount }}</span
                    ><span>帮扶课堂</span>
                </p>
            </div>
        </div>
        <div class="courier-banner-item">
            <p class="courier-item-name">本学期课程开设情况</p>
            <div class="team-courses">
                <p>
                    <span>{{ state.termCountCount }}</span
                    ><span>本学期课时</span>
                </p>
                <p>
                    <span>{{ state.teacherCount }}</span
                    ><span>主讲老师人数</span>
                </p>
                <p>
                    <span>{{ state.studentCount }}</span
                    ><span>受益学生人数</span>
                </p>
            </div>
        </div>
        <div class="courier-banner-item">
            <p class="courier-item-name">本周课程开设情况</p>
            <div class="team-courses">
                <p>
                    <span>{{ state.weekPlanCount }}</span
                    ><span>本周课时</span>
                </p>
                <p class="two">
                    <span>{{ state.weekShouldTaughtCount }}</span
                    ><span>未授课时</span>
                </p>
                <p class="three">
                    <span>{{ state.weekTaughtCount }}</span
                    ><span>已授课时</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { reactive, onMounted } from 'vue'
    import { getCityList, courierStatistics } from '@/service/api/courier'

    const state = reactive({
        cityName: '',
        liveDeviceCount: 0,
        helpDeviceCount: 0,
        termCountCount: 0,
        studentCount: 0,
        teacherCount: 0,
        weekPlanCount: 0,
        weekTaughtCount: 0,
        weekShouldTaughtCount: 0
    })

    const initCity = async () => {
        const res = await getCityList()
        state.cityName = res[0].area_name
    }

    const initLiveDevice = async () => {
        const res = await courierStatistics()
        if (res) {
            state.liveDeviceCount = res.main_course_count
            state.helpDeviceCount = res.help_course_count
            state.termCountCount = res.this_term_plan_count
            state.studentCount = res.this_term_student_count
            state.teacherCount = res.this_term_teacher_count
            state.weekPlanCount = res.this_week_plan_count
            state.weekTaughtCount = res.this_week_complete_count
            state.weekShouldTaughtCount = res.this_week_not_start_count
        }
    }

    onMounted(() => {
        initLiveDevice()
    })
</script>

<style lang="scss" scoped>
    .courier-banner-wrap {
        width: 368px;
        height: 356px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        background: rgba(255, 255, 255, 0.97);
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 120px;
        transform: translateY(-50%);
        font-size: 16px;

        .courier-banner-item {
            width: 100%;

            .courier-item-name {
                @include font($color: #596081, $fontWeight: $fontWeightCrude);
            }

            .team-courses {
                display: flex;
                flex-wrap: wrap;
                padding-top: 5px;

                > p {
                    display: flex;
                    flex-direction: column;
                    span:nth-child(1) {
                        @include font($color: $systemThemeColor, $fontWeight: $fontWeightMiddle, $fontSize: 28px);
                    }
                    span:nth-child(2) {
                        @include font($color: #a0a7c6, $fontWeight: $fontWeightMiddle, $fontSize: $fontSizeSmall);
                    }
                }

                > p:nth-child(n + 2) {
                    margin-left: 36px;
                }

                .two {
                    margin-left: 51px !important;
                }

                .three {
                    margin-left: 64px !important;
                }
            }

            &:nth-child(n + 2) {
                padding-top: 12px;
                border-top: 1px solid #e6e9f4;
            }
        }
    }
</style>
