/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-11 16:42:25
 * @LastEditTime: 2023-09-13 16:06:47
 * @FilePath: /three-class-fe-1/src/service/index.ts
 */
import axios, { AxiosResponse } from 'axios'

import notification from '@/utils/notification'
import qs from 'qs'
import router from '@/router'
import { getZoneListTree } from '@/service/api/common'

axios.defaults.timeout = 100000


axios.interceptors.response.use(
    function (response: AxiosResponse<any>) {
        if (response.request.responseType == 'arraybuffer') {
            return response
        }
            if (response && response.status === 200) {
                if (response.data.code === 2001) {
                    router.push('/login')
                }
                if (response.data.code !== 0) {
                    notification('error', response.data.msg)
                }
                return Promise.resolve(response.data)
            }
       
        return response
    },
    function (error: Error) {
        throw error
    }
)

axios.interceptors.request.use(
    (config) => {
        if (config.method === 'get') {
            config.params = {
                    ...config.params
                }
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

const BASE_URL = '/api'

export const get = <T>(url: string, params?: {}, config?:{}): Promise<T> => {
    return axios
        .get(BASE_URL + url, { params, ...config })
        .then((response: AxiosResponse<T>) => {
            return response.data
        })
        .catch((error: Error) => {
            throw error
        })
}

export const post = <T>(url: string, params?: {}, isJson: boolean = false): Promise<T> => {
    return axios
        .post(BASE_URL + url, qs.stringify(params))
        .then((response: AxiosResponse<T>) => {
            return response.data
        })
        .catch((error: Error) => {
            throw error
        })
}

export const deleteRes = <T>(url: string, params?: {}): Promise<T> => {
    return axios
        .delete(BASE_URL + url, { params })
        .then((response: AxiosResponse<T>) => {
            return response.data
        })
        .catch((error: Error) => {
            throw error
        })
}

export const post1 = (url: string, params: any, format?: any) => {
    if (format !== 'json') {
        params = qs.stringify(params)
    }
    return new Promise((resolve, reject) => {
        axios
            .post(BASE_URL + url, params)
            .then((response: any) => {
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
    })
}
