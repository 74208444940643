/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-08 10:55:40
 * @LastEditTime: 2023-09-13 17:29:39
 * @FilePath: /three-class-fe-1/src/service/api/user.ts
 */
import * as service from '../index'

/**
 * 获取用户信息
 */
export function getUserInfo(): Promise<any> {
    return service.get('/account/getUserinfo')
}

/**
 * 修改用户密码
 */
export function updatePassword(params: {password: string, confirm_password: string; id: number }): Promise<boolean> {
    return service.post('/personal/user/changePwd', params)
}

/**
 * 获取功能开关
 */
export function getFunctionStatus(): Promise<any> {
    return service.get('/web/custom_config/application')
}

/**
 * 获取boom sign
 */
export function getBoomSign() {
	return service.get('/boom/signature',{})
}

/**
 * 获取boom服务地址
 */
export function getBoomService() {
    return service.get('/boom/urls', {})
}

/**
 * 获取省市区结构
 */
export function getAreaTree() {
    return service.get('/admin/area/list', {})
}

/**
 * 获取学校列表
 */
export function getSchoolList(params: {
    name?: string
    page?: number
    page_size?: number
    province_id?: any
    city_id?: any
    area_id?: any
}): Promise<any> {
    return service.get('/web/school/list', params)
}

/**
 * 获取年级
 */
export function getGradeList(): Promise<any> {
    return service.get('/admin/grade/list', {})
}

/**
 * 创建账号
 */
export function createUser(params: any): Promise<any> {
    return service.post('/web/user/register', params)
}
