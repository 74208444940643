<!--
 * @Author: liujian
 * @Description: 
 * @Date: 2021-08-31 11:29:05
 * @LastEditTime: 2022-02-24 20:15:38
 * @FilePath: /three-class-fe-1/src/components/share/breadcrumb.vue
-->
<template>
    <div class="bread" v-if="state.relateRouteArr && state.relateRouteArr.length">
        <el-breadcrumb :separator-icon="ArrowRightBold">
            <el-breadcrumb-item v-for="item in state.relateRouteArr" :key="item.path" :to="{ path: '/' + item.path }">
                {{ item.label }}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script lang="ts" setup>
    import { reactive, watch } from 'vue'
    import { useRoute, useRouter, RouteRecordRaw } from 'vue-router'
    import { ArrowRightBold } from '@element-plus/icons-vue'

    type BreadItem = {
        label: string | unknown
        path: string
    }

    const route = useRoute()
    const router = useRouter()

    const state = reactive({
        relateRouteArr: [] as BreadItem[]
    })

    /**
     * 组装面包屑数据
     */
    const initBreadArr = () => {
        const routerNames = route.fullPath
            .split('/')
            .filter((e) => e)
            .map((e) =>
                e.replace(/(\-([a-z]))/g, (match, p1, p2) => {
                    return p2.toUpperCase()
                })
            )
        const currentRouteBread = {
            label: route.meta?.label,
            path: route.fullPath.substr(1, route.fullPath.length)
        }
        routerNames.splice(routerNames.length - 1, 1)
        state.relateRouteArr = matchRoutes(router.options.routes, routerNames)
        state.relateRouteArr.push(currentRouteBread)
    }

    /**
     * 匹配路由
     */
    const matchRoutes = (routes: RouteRecordRaw[], matchRouteNames: string[], result: BreadItem[] = []) => {
        for (let i = 0; i < routes.length; i++) {
            if (matchRouteNames.length === 0) {
                break
            }
            const routeItem = routes[i]
            const index = matchRouteNames.findIndex((e) => e === routeItem.name)
            if (index !== -1) {
                const item = {
                    label: routeItem.meta?.label,
                    path: routeItem.path
                }
                result.push(item)
                matchRouteNames.splice(index, 1)
            }
            if (routeItem.children) {
                matchRoutes(routeItem.children, matchRouteNames, result)
            }
        }
        return result
    }

    /**
     * 路由变化，变更banner
     */
    watch(
        () => route.fullPath,
        () => {
            initBreadArr()
        },
        { immediate: true }
    )
</script>

<style scoped lang="scss">
    .bread {
        margin-top: 48px;
        margin-bottom: 48px;
    }
</style>
