/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-23 22:57:32
 * @LastEditTime: 2022-02-24 20:08:42
 * @FilePath: /three-class-fe-1/src/utils/notification.ts
 */
import { ElMessage } from 'element-plus'

const messageArr: any[] = []

export default function (type: 'success' | 'warning' | 'info' | 'error', message: string, title: string = '提示', duration: number = 3000) {
    if (message) {
        if (messageArr.length >= 3) {
            messageArr.shift().close()
        }
        messageArr.push(
            ElMessage({
                type,
                message,
                duration
            })
        )
    }
}
