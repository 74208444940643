/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-08-30 15:42:43
 * @LastEditTime: 2023-09-25 11:10:31
 * @FilePath: /three-class-fe-1/src/router/constant.ts
 */
export enum RouterName {
    LOGIN = 'login',
    HOME = 'home',
    // 新闻、消息
    NEWS_LIST = 'newsList',
    NEWS_DETAIL = 'newsDetail',
    // 专递
    FAMOUS_COURIER = 'famousCourier',
    FAMOUS_COURIER_WORKPAIRS = 'workpairs',
    FAMOUS_COURIER_AREA_PAIR = 'areaPair',
    FAMOUS_COURIER_REGIONAL_PAIRING_DETAIL = 'courierAreaPairDetail',
    FAMOUS_COURIER_LIVE = 'famousCourierLive',
    // 名师
    FAMOUS_TEACHER = 'famousTeacher',
    FAMOUS_STUDENT = 'famousStudent',
    TEACHER_DETAIL = 'teacherDetail',
    STUDENT_DETAIL = 'studentDetail',
    FAMOUS_TEACHER_TEACHER_RESEARCH = 'teacherResearch',
    FAMOUS_TEACHER_RESEARCH_DETAIL = 'teacherResearchDetail',
    FAMOUS_TEACHER_RESEARCH_LIVE = 'teacherResearchLive',
    FAMOUS_TEACHER_COMMENT = 'teacherComment',
    FAMOUS_TEACHER_STUDIO = 'teacherStudio',
    FAMOUS_TEACHER_COMMENT_DETAIL = 'teacherCommentDetail',
    FAMOUS_TEACHER_COMMENT_LIVE = 'teacherCommentLive',
    FAMOUS_TEACHER_STUDIO_DETAIL = 'teacherStudioDetail',
    FAMOUS_TEACHER_COMPETITION_LIST = 'teacherCompetition',
    FAMOUS_TEACHER_COMPETITION_DETAIL = 'teacherCompetitionDetail',
    FAMOUS_TEACHER_COMPETITION_JOIN = 'teacherCompetitionJoin',
    FAMOUS_TEACHER_COMPETITION_JOIN_EDIT = 'teacherCompetitionJoinEdit',
    FAMOUS_TEACHER_COMPETITION_PRODUCT = 'teacherCompetitionProduct',
    // 名校
    FAMOUS_SCHOOL = 'famousSchool',
    FAMOUS_SCHOOL_EXCELLENT_COURSE_LIST = 'excellentCourse',
    FAMOUS_SCHOOL_EXCELLENT_COURSE_LIVE = 'excellentCourseLive',
    FAMOUS_SCHOOL_SPACE = 'famousSchoolSpace',
    FAMOUS_SCHOOL_SPACE_DETAIL = 'famousSchoolSpaceDetail',
    FAMOUS_SCHOOL_EXCELLENT_COURSE_DEMAND_LIST = 'excellentDemandList',
    FAMOUS_SCHOOL_EXCELLENT_COURSE_DEMAND_LIVE = 'excellentDemandLive',
    // 资源
    RESOURCE_CENTER = 'resourceCenter',
    RESOURCE_DETAIL = 'resourceDetail',
    //直播
    LIVE = 'live',
    LIVE_DETAIL = 'liveDetail',
    //录播
    RECORD = 'record',
    RECORD_DETAIL = 'recordDetail',

    //融合
    AIR_COURSE = 'airCourse',
    AIR_COURSE_DETAIL = 'airCourseDetail',

    // 开放直播
    OPEN_LIVE = 'openLive',
    OPEN_LIVE_DETAIL = 'openLiveDetail',

    //关于我们
    ABOUT_US = 'aboutUs',
    PROJECT_LIST = 'projectList',
    PROJECT_DETAIL = 'projectDetail',
    PERSONAL_CENTER = 'personalCenter',
    SCHOOL_LIST = 'schoolList',
    COMPETITION = 'competition',
    CENTER_APPLY = 'centerApply',
    INFO_DOWNLOAD = 'infoDownload',
    GRADES = 'grades'
}

export enum RouterPath {
    // 登录页
    LOGIN = 'login',
    // 首页
    HOME = 'home',
    // 新闻、消息列表
    NEWS_LIST = 'newsList',
    // 专递课堂首页
    FAMOUS_COURIER = 'famous-courier',
    // 专递课堂-校校结对-结对组详情
    FAMOUS_COURIER_REGIONAL_PAIRING_DETAIL = 'courier/area-pair/detail',
    // 专递课堂直播页
    FAMOUS_COURIER_WORKPAIRS = 'workpairs',
    // 名师首页
    FAMOUS_TEACHER = 'famous-teacher',
    //优秀学生
    FAMOUS_STUDENT = 'famous-student',
    //
    SCHOOL_LIST = 'school-list',
    // 名师-名师详情
    TEACHER_DETAIL = 'teacher-detail',
    // 名师-学生详情
    STUDENT_DETAIL = 'student-detail',
    // 名师-教研活动列表
    FAMOUS_TEACHER_TEACHER_RESEARCH = 'teacher-research',
    // 名师-教研-直播
    FAMOUS_TEACHER_RESEARCH_LIVE = 'teacher-research-live',
    // 名师-评课活动列表
    FAMOUS_TEACHER_COMMENT = 'teacher-comment',
    // 名师-评审活动
    FAMOUS_TEACHER_COMPETITION = 'teacher-competition',
    // 名师-评审活动-报名表
    FAMOUS_TEACHER_COMPETITION_JOIN = 'teacher-competition-join',
    // 名师-评审活动-报名表-编辑
    FAMOUS_TEACHER_COMPETITION_JOIN_EDIT = 'teacher-competition-join-edit',
    // 名师-评审活动-作品详情
    FAMOUS_TEACHER_COMPETITION_PRODUCT = 'teacher-competition-product',
    // 名师-名师工作室
    FAMOUS_TEACHER_STUDIO = 'teacher-studio',
    // 名师-教研-直播
    FAMOUS_TEACHER_COMMENT_LIVE = 'teacher-comment-live',
    // 名校首页
    FAMOUS_SCHOOL = 'famous-school',
    // 名校-优课列表
    FAMOUS_SCHOOL_EXCELLENT_COURSE_LIST = 'excellent-course',
    // 名校列表
    FAMOUS_SCHOOL_EXCELLENT_COURSE_DEMAND_LIST = 'excellent-demand-list',
    // 名校空间列表
    FAMOUS_SCHOOL_SPACE = 'famous-school-space',
    // 资源
    RESOURCE_CENTER = 'resource-center',
    //直播列表
    LIVE_COURSE = 'live',
    //录播列表
    RECORD_COURSE = 'record',
    //融合课堂列表
    AIR_COURSE = 'air-course',
    //融合课程详情
    AIR_COURSE_DETAIL = 'air-course',
    // 开放直播
    OPEN_LIVE = 'open-live',
    // 开放直播详情
    OPEN_LIVE_DETAIL = 'open-live-detail',
    //关于我们
    ABOUT_US = 'about-us',
    PROJECT_LIST = 'project-list',
    PROJECT_DETAIL = 'project-detail',
    PERSONAL_CENTER = 'personal-center',
    COMPETITION = 'competition',
    CENTER_APPLY = 'center-apply',
    INFO_DOWNLOAD = 'info-download',
    GRADES = 'grades'
}
